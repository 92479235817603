button {
  background: none;
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
  font-family: inherit;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

button.button {
  padding: 4px 16px;
  border-radius: 13.5px;
}

button.icon-button {
  padding: 6px;
}

button.button.medium {
  padding: 8px 20px;
  border-radius: 17.5px;
}

button.add-field {
  background-color: var(--secondary);
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 4px 8px;
  margin-bottom: 16px;
}

button.add-field .icon {
  margin-right: 8px;
}