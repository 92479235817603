.overlay {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: var(--overlay);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal {
  width: 90%;
  max-width: 460px;
  max-height: 90%;
  box-sizing: border-box;
  border-radius: 4px;
  background: var(--white);
  box-shadow: 0 1px 6px 0 rgba(21, 21, 21, 0.12), 0 1px 4px 0 rgba(21, 21, 21, 0.12);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  overflow: auto;
}

.modal-content {
  padding: 16px 16px 0;
}

label {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

label:last-child {
  margin-bottom: 0;
}

label span {
  margin-bottom: 8px;
}

label textarea {
  min-height: 80px;
}

label input,
label textarea {
  padding: 8px;
  outline: none;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid var(--light-gray);
  color: var(--black);
  font-weight: 400;
  font-family: inherit;
  background-color: var(--white);
}

label .body.error {
  margin-top: 8px;
}

.modal .codes,
.modal > .heading,
.modal > .button,
.modal > .body {
  margin-bottom: 16px;
}

.modal .buttons {
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  position: sticky;
  bottom: 0;
  background-color: var(--white);
  margin-bottom: 0;
}

.modal .buttons .button:last-child {
  margin-left: 16px;
}

.react-datepicker-wrapper,
.react-datepicker-wrapper input {
  width: 100%;
  box-sizing: border-box;
}

.modal .modal-content > .heading {
  margin-bottom: 16px;
}

.modal .photo,
.modal .image {
  width: 100%;
}

.modal .heading {
  margin-bottom: 16px;
}
