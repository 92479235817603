:root {
  --black: #2C2D30;
  --gray: #A0A0A2;
  --dark-gray: #717274;
  --light-gray: #BFBBB9;
  --lighter-gray: #e6e6e6;
  --soft-gray: #E8E8E8;
  --white: #FFFFFF;
  --off-white: #F9F9F9;
  --primary: #0B643D;
  --dark-primary: #084637;
  --light-primary: #A6D0BE;
  --soft-primary: #D1E6DD;
  --softer-primary: #F5F8F5;
  --secondary: #3943F6;
  --light-secondary: #D4D7FF;
  --soft-secondary: #A0A4F6;
  --translucent-secondary: #3943F615;
  --overlay: rgba(21, 21, 21, 0.85);
  --error-red: #EE1C10;
  --warning-orange: #E97509;
  --informational-yellow: #F8E8CE;
  --success-green: #00901E;
  --cubic-bezier: cubic-bezier(.16,.62,.83,.67);
  --paper: rgba(21, 21, 21, 0.16) 0px 3px 10px, rgba(21, 21, 21, 0.23) 0px 3px 10px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--off-white);
}

html,
body,
#root {
  height: 100%;
}

main {
  min-height: 100%;
  display: flex;
}

.icon {
  width: 28px;
  height: 28px;
  display: block;
}

.icon.small {
  width: 20px;
  height: 20px;
}

.icon.large {
  width: 44px;
  height: 44px;
}

label {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

label:last-child {
  margin-bottom: 0;
}

label span {
  margin-bottom: 8px;
}

label textarea {
  min-height: 80px;
}

label input,
label textarea {
  padding: 8px;
  outline: none;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid var(--light-gray);
  color: var(--black);
  font-weight: 400;
  font-family: inherit;
  background-color: var(--white);
  box-sizing: border-box;
}

label .body.error {
  margin-top: 8px;
}

.page {
  flex: 1;
  padding: 24px;
  box-sizing: border-box;
  margin: 0 auto;
}

.Dropdown-root,
.buttons {
  margin-bottom: 16px;
}

.Dropdown-control {
  border-radius: 4px;
  padding: 9px 52px 9px 10px;
}

.body.light {
  margin-bottom: 8px;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.trend-indicator > h1.heading {
  margin-bottom: 16px;
  padding-bottom: 8px;
  border-bottom: 1px solid var(--light-gray);
}

.page > .heading-secondary,
.option > .heading-secondary,
.trend-indicator > .heading-secondary {
  margin-bottom: 8px;
}

.field {
  margin-bottom: 16px;
}

.field button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  background-color: var(--lighter-gray);
  padding: 8px;
}

.field button[type="submit"] {
  width: initial;
  float: right;
}

.field:last-child {
  margin-bottom: 0;
}

.field .row {
  display: flex;
}

.field .Dropdown-root {
  min-width: 112px;
  margin-right: 16px;
  margin-bottom: 0;
}

.field .Dropdown-root:last-child {
  margin-right: 0;
}

.sketch-picker {
  box-shadow: none!important;
  border: 1px solid var(--light-gray);
  margin-bottom: 16px;
}

hr.divider {
  box-sizing: border-box;
  height: 1px;
  background-color: var(--light-gray);
  border: 0;
  margin: 16px 0;
}

.login h1,
.login .error,
.upload h1,
.upload .error,
.page .option {
  margin-bottom: 16px;
}

.page h1:first-child {
  margin-bottom: 16px;
}

.images {
  display: flex;
  margin: -8px -8px 16px;
}

.images section {
  width: 200px;
  margin: 8px;
}

.images p {
  margin-bottom: 8px;
}

.images img {
  display: block;
  width: 100%;
}

.mt10 {
  margin-top: 10px;
}

.mt20 {
  margin-top: 20px;
}

.mh10 {
  margin-right: 10px;
  margin-left: 10px;
}

.mb10 {
  margin-bottom: 10px !important;
}


.row-container {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin: 16px 0px 16px 0px; 
}

.row {
  display: flex;
}

.row.start {
  align-items: flex-start;
}

.row.center {
  align-items: center;
}

