.list-item {
  text-decoration: none;
  display: block;
  width: 100%;
  text-align: left;
  background-color: var(--white);
}

.list-item .list-item-row {
  padding: 8px;
  display: flex;
  align-items: center;
}

.list-item .list-item-row > section {
  flex: 1;
}

hr.divider {
  box-sizing: border-box;
  height: 1px;
  background-color: var(--light-gray);
  border: 0;
  margin: 0;
}

.list-item .body {
  margin-top: 4px;
}

.list-item .icon-left {
  margin-right: 8px;
}

.list-item .icon-right {
  margin-left: 8px;
}