.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  z-index: 999; 
}

.modal {
  z-index: 1000; 
}

.modal-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.modal h2 {
  margin: 0;
  font-size: 20px;
  color: #333;
  text-align: center;
}

.modal button {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.modal .button.save {
  background-color: #4caf50;
  color: #ffffff;
}

.modal .button.cancel {
  background-color: #f44336;
  color: #ffffff;
}

.modal .button.contrast {
  background-color: #2196f3;
  color: #ffffff;
}

.button.danger {
  background-color: #f44336;
  color: #ffffff;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s ease-in-out;
}

.button.danger:hover {
  background-color: #d32f2f;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-50%, -45%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

.tag-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.tag {
  width: 100%;
  margin-right: 10px;
}
  
.option-buttons {
  flex-direction: row;
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.option-buttons .button {
  background-color: #2196f3; 
  color: #ffffff;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.option-buttons .button:hover {
  background-color: #1976d2; 
}

.option-buttons .button.danger {
  background-color: #f44336;
}

.option-buttons .button.danger:hover {
  background-color: #d32f2f;
}