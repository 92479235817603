.blog-list {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
  }
  
  .blog-item {
    display: flex;
    gap: 1rem;
    padding: 1rem;
    border: 1px solid #ddd;
    border-right: 0px;
    border-left: 0px;
    cursor: pointer;
  }
  
  .blog-item.selected {
    background-color: #f0f8ff;
  }
  
  .blog-thumbnail {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 4px;
  }
  
  .pagination-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #f9f9f9;
    border-top: 1px solid #ddd;
    padding: 1rem;
    display: flex;
    justify-content: center;
  }
  
  .pagination-controls {
    display: flex;
    gap: 1rem;
    align-items: center;
  }

  .empty-state {
    text-align: center;
    margin-top: 2rem;
    font-size: 1.2rem;
    color: #666;
  }
  