.alphabet {
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  color: var(--primary);
}

.body {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: var(--black);
}

.body.light,
.heading-secondary.light {
  color: var(--dark-gray);
}

.body.lighter {
  color: var(--light-gray);
}

.body.contrast {
  color: var(--white);
}

.body.error,
.button.destructive {
  color: var(--error-red);
}

.button {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  color: var(--black);
}

.button.accent.secondary {
  color: var(--secondary);
}

p.divider {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: var(--dark-gray);
}

.heading {
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  color: var(--black);
}

.heading-large {
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  color: var(--black);
}

.heading-secondary {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  color: var(--black);
}

.navigation {
  font-size: 17px;
  font-weight: 400;
  line-height: 22px;
  color: var(--black);
  text-decoration: none;
}

.navigation.emphasized {
  font-size: 17px;
  font-weight: 600;
  line-height: 22px;
  color: var(--black);
}

.body.accent,
.navigation.accent,
.heading.accent,
.heading-large.accent,
.heading-secondary.accent,
.button.accent {
  color: var(--primary);
}

.numeric-input {
  font-size: 28px;
  font-weight: 600;
  line-height: 38px;
  color: var(--black);
}

.body.contrast,
.heading.contrast,
.heading-large.contrast,
.heading-secondary.contrast,
.navigation.contrast,
.navigation-emphasized.contrast,
.button.contrast {
  color: var(--white);
}
