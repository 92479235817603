.menu {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  position: relative;
}

.menu nav {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

nav {
  border-right: 1px solid var(--light-gray);
}

nav a {
  display: block;
  padding: 12px;
  color: var(--black);
  text-decoration: none;
  font-weight: 700;
  transition: background-color .2s ease-in-out;
  border-bottom: 1px solid var(--light-gray);
}

nav a:hover {
  background-color: var(--soft-primary);
}

nav a.active {
  color: var(--primary);
}

.upload-container {
  position: fixed;
  bottom: 0;
  left: 35px;
  padding: 1rem;
}

.logout-container {
  margin-left: 90px;
  position: fixed;
  bottom: 0;

}

  .upload-link{
    color: var(--secondary);
    text-decoration: none;
    font-size: 24px;
}

