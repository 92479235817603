.drawer {
  height: auto
}
.drawer .overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--overlay);
  z-index: 2;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s var(--cubic-bezier) 0s;
}

.drawer.open .overlay {
  pointer-events: auto;
  opacity: 1;
}

.drawer .drawer-content {
  width: 240px;
  height: 100%;
  background-color: var(--white);
  transition: transform 0.3s ease-in-out 0s;
  position: fixed;
  top: 0;
  z-index: 2;
}

.drawer .drawer-content > section {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.drawer.right .drawer-content {
  width: 296px;
}

.drawer-right-header-wrapper {
  padding: 16px
}

.drawer.right .drawer-content > section > * {
  padding: 16px;
  box-sizing: border-box;
}


.drawer.right .drawer-content > section .drawer-nav {
  padding: 0px;
  display: flex;
  flex-direction: column;
}

.drawer.left .drawer-content {
  transform: translateX(-300px);
  left: 0;
}

.drawer.right .drawer-content {
  transform: translateX(296px);
  right: 0;
}

.drawer.open .drawer-content {
  transform: none;
}

.drawer-header {
  margin-bottom: 16px;
}

.drawer-item {
  margin-bottom: 16px;
  clear: both;
  display: flex;
  flex-direction: column;
}

.drawer-item.selected {
  background: var(--softer-primary);
}


.drawer-item.selected button.nav-button {
  color:var(--black);
  font-weight: 600;
}

button.nav-button {
  font-weight: 400;
  text-align: left;
  font-size: 16px;
  text-decoration: underline;
  color:var(--primary);
  padding: 9px 16px;
}

.drawer-item header {
  margin-bottom: 12px;
}

.drawer-item > section:not(.list-item) {
  margin-bottom: 12px;
}

.drawer-buttons {
  position: relative;
  padding-left: 48px;
  padding-right: 48px;
  margin-bottom: 16px;
}

.drawer-buttons .drawer-button {
  position: absolute;
  top: -6.5px;
}

.drawer-buttons .drawer-button.left {
  left: 0;
}

.drawer-buttons .drawer-button.right {
  right: 0;
}

@media (min-width: 768px) {
  .drawer.left .drawer-content {
    transform: none;
    position: relative;
  }

  .drawer-button.left {
    display: none;
  }

  .drawer-buttons {
    padding-left: 0;
  }
}

@media (min-width: 1160px) {
  .drawer.right .drawer-content {
    transform: none;
    position: relative;
  }

  .drawer-button.right {
    display: none;
  }

  .drawer-buttons {
    padding-right: 0;
  }
}

.unread {
  position: relative;
}

.unread .badge {
  position: absolute;
  top: -8px;
  right: -8px;
}

.badge {
  background-color: var(--primary);
  border-radius: 12px;
  box-sizing: border-box;
  box-shadow: rgba(21, 21, 21, 0.12) 0 1px 6px, rgba(21, 21, 21, 0.12) 0 1px 4px;
  color: var(--white);
  font-size: 12px;
  height: 20px;
  width: 20px;
  line-height: 14px;
  padding: 3px;
  text-align: center;
}

.user-title {
  margin-left: 8px;
  margin-bottom: 3px;
  font-size: 16px;
  font-weight: 400;
}

.container {
  display: flex;
  flex-direction: column;
 }

 .locale-filter {
  margin-bottom: 1.5rem;
  padding: 1rem;
  margin: 1rem;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.locale-filter h3 {
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
  font-weight: bold;
}

.locale-option {
  display: inline-block;
  margin-right: 1rem;
  font-size: 1rem;
  color: #333;
  cursor: pointer;
}

.locale-option input {
  margin-right: 0.5rem;
  cursor: pointer;
}

.locale-option input:checked + label {
  font-weight: bold;
}

